import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import ReactGA from "react-ga4"

const TRACKING_ID = "G-GSN94ZY5MZ"
ReactGA.initialize(TRACKING_ID)

export const PageTracker = () => {
  const location = useLocation()

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    })
  }, [location])

  return null
}
