import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import RegisterPage from "./pages/RegisterPage"
import WalletPage from "./pages/WalletPage"
import Layout from "./Layouts/Layout"
import CurrencyPage from "./pages/CurrencyPage"
import LoginPage from "./pages/LoginPage"
import PaymentPage from "./pages/PaymentPage"
import PrivateRoute from "./routes/PrivateRoute"
import VortexPersonalPage from "./pages/VortexPersonalPage"
import VortexBusinessPage from "./pages/VortexBusinessPage"
import VortexPaymentPage from "./pages/VortexPaymentPage"
import VortexTaxesPage from "./pages/VortexTaxesPage"
import VortexWeb3Page from "./pages/VortexWeb3Page"
import VortexAMLPage from "./pages/VortexAMLPage"
import SelectSendPage from "./pages/SelectSendPage"
import SendPage from "./pages/SendPage"
import ReceivePage from "./pages/ReceivePage"
import SwapPage from "./pages/SwapPage"
import IntegrationPage from "./pages/IntegrationPage"
import OfflineStorePage from "./pages/OfflineStorePage"
import WebsitePage from "./pages/WebsitePage"
import AccountPage from "./pages/AccountPage"
import InvoicePage from "./pages/InvoicePage"
import TransferPage from "./pages/TransferPage"
import SelectReceivePage from "./pages/SelectReceivePage"
import InvoicePayPage from "./pages/InvoicePayPage"
import { PageTracker } from "./utils/PageTracker"

function App() {
  return (
    <Router>
      <PageTracker />
      <Routes>
        <Route path="/" element={<VortexPersonalPage />} />
        <Route path="/business" element={<VortexBusinessPage />} />
        <Route path="/vortex-payment" element={<VortexPaymentPage />} />
        <Route path="/taxes" element={<VortexTaxesPage />} />
        <Route path="/web3" element={<VortexWeb3Page />} />
        <Route path="/aml" element={<VortexAMLPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/login" element={<LoginPage />} />

        <Route
          path="/"
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute>
          }
        >
          <Route path="wallet" element={<WalletPage />} />
          <Route path="account" element={<AccountPage />} />
          <Route path="send" element={<SelectSendPage />} />
          <Route path="send/:isoCode" element={<SendPage />} />
          <Route path="receive" element={<SelectReceivePage />} />
          <Route path="receive/:isoCode" element={<ReceivePage />} />
          <Route path="swap" element={<SwapPage />} />
          <Route path="currency/:isoCode" element={<CurrencyPage />} />
          <Route path="transfer" element={<TransferPage />} />
          <Route path="invoice" element={<InvoicePage />} />
          <Route path="payment" element={<PaymentPage />} />
          <Route path="integration" element={<IntegrationPage />} />
          <Route
            path="/integration/offline-store/:offlineStoreId"
            element={<OfflineStorePage />}
          />
          <Route
            path="/integration/website/:websiteId"
            element={<WebsitePage />}
          />
        </Route>
        <Route path="invoice/:invoiceId" element={<InvoicePayPage />} />
      </Routes>
    </Router>
  )
}

export default App
