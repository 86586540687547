import { useQuery, useMutation } from "@apollo/client"
import { useState } from "react"
import { ALL_STATIC_INVOICES } from "../apollo/graphql/Query/allStaticInvoices"
import { TOGGLE_STATIC_INVOICE_STATUS } from "../apollo/graphql/Mutation/toggleStaticInvoiceStatus"
import { DELETE_STATIC_INVOICE } from "../apollo/graphql/Mutation/deleteStaticInvoice"
import Card from "../assets/UI/Card"
import Modal from "../assets/UI/Modal"
import Currency from "../assets/UI/Currency"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Button from "../assets/UI/Button"
import styles from "../assets/styles/Components/AllStaticInvoice.module.css"

const AllStaticInvoice = ({ walletId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedInvoice, setSelectedInvoice] = useState(null)
  const [isConfirmDelete, setIsConfirmDelete] = useState(false)

  const { loading, error, data, refetch } = useQuery(ALL_STATIC_INVOICES, {
    variables: { walletId },
    skip: !walletId,
    fetchPolicy: "network-only",
  })

  const [toggleStatus] = useMutation(TOGGLE_STATIC_INVOICE_STATUS, {
    onCompleted: (data) => {
      if (data.toggleStaticInvoiceStatus.ok) {
        toast.success("Invoice status updated successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          style: {
            backgroundColor: "#070707",
            color: "#ffffff",
          },
        })
        handleCloseModal()
        refetch()
      } else {
        toast.error("Error updating invoice status")
      }
    },
    onError: () => {
      toast.error("Error updating invoice status")
    },
  })

  const [deleteInvoice] = useMutation(DELETE_STATIC_INVOICE, {
    onCompleted: (data) => {
      if (data.deleteStaticInvoice.ok) {
        toast.success("Invoice deleted successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          style: {
            backgroundColor: "#070707",
            color: "#ffffff",
          },
        })
        handleCloseModal()
        refetch()
      } else {
        toast.error("Error deleting invoice")
      }
    },
    onError: () => {
      toast.error("Error deleting invoice")
    },
  })

  const handleCardClick = (invoice) => {
    setSelectedInvoice(invoice)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setSelectedInvoice(null)
    setIsConfirmDelete(false)
  }

  const handleToggleStatus = () => {
    toggleStatus({
      variables: {
        invoiceId: selectedInvoice.id,
        walletId: walletId,
      },
    })
  }

  const handleDeleteInvoice = () => {
    if (isConfirmDelete) {
      deleteInvoice({
        variables: {
          invoiceId: selectedInvoice.id,
          walletId: walletId,
        },
      })
    } else {
      setIsConfirmDelete(true)
    }
  }

  const handleOpenLink = (invoiceId) => {
    window.open(
      `https://vortexonline.xyz/invoice/${invoiceId}`,
      // `http://localhost:3000/invoice/${invoiceId}`,
      "_blank",
      "noopener,noreferrer"
    )
  }

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error fetching invoices: {error.message}</p>

  return (
    <div className={styles.container}>
      {data.allStaticInvoices.map((invoice) => (
        <Card
          key={invoice.id}
          onClick={() => handleCardClick(invoice)}
          inactive={invoice.status === "INACTIVE"}
        >
          <div className={styles.card}>
            <p className={styles.cardName}>{invoice.name}</p>
            <Currency
              currency={{
                id: invoice.network.id,
                logoUrl: invoice.network.logoUrl,
                name: invoice.network.fullName,
                isoCode: invoice.network.name,
              }}
              variant="simple"
            />
            <Button
              onClick={() => handleOpenLink(invoice.id)}
              disabled={invoice.status === "INACTIVE"}
            >
              Link
            </Button>
          </div>
        </Card>
      ))}
      <ToastContainer />
      {isModalOpen && selectedInvoice && (
        <Modal
          title={`Invoice #${selectedInvoice.id}`}
          onClose={handleCloseModal}
        >
          <p>
            <strong>Status:</strong> {selectedInvoice.status}
          </p>
          <p>
            <strong>Created At:</strong>{" "}
            {new Date(selectedInvoice.createdAt).toLocaleString()}
          </p>

          <div className={styles.modalActions}>
            <Button onClick={handleToggleStatus}>
              {selectedInvoice.status === "ACTIVE"
                ? "Deactivate invoice"
                : "Activate Invoice"}
            </Button>

            <button
              className={`${styles.buttonCustom} ${styles.buttonDanger}`}
              onClick={handleDeleteInvoice}
            >
              {isConfirmDelete ? "Are you sure?" : "Delete Invoice"}
            </button>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default AllStaticInvoice
